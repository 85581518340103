
export class AuthorizeService {
    async isAuthenticated() {
        const response = await fetch(`account/userprofile`, {
        });
        const data = await response.json();
        return data.isAuthenticated;
    }

    async validateAccessToken() {
        if (typeof window !== 'undefined') {
            const isAuthenticated = await this.isAuthenticated();
            if(isAuthenticated === false){
                window.location.href = "/account/login";
            }
        }
    }

    static get instance() { return authService }
}

const authService = new AuthorizeService();

export default authService;
