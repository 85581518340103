import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { DropzoneArea } from 'material-ui-dropzone';

// assets
import imageNotFound from 'assets/img/image-not-found.png';

import {
  saveStepData,
  populateStepData,
  uploadAssignmentImage,
} from 'services/dataServices.js';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import CircularProgress from '@material-ui/core/CircularProgress';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';

const useStyles = makeStyles((theme) => ({
  media: {
    width: '50%',
    height: 300,
  }
}));

export default function Step0(props) {
  const classes = useStyles();
  const { customerId, assignmentId } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [frontHouseImageURL, setFrontHouseImageURL] = useState(imageNotFound);
  const [sketchImageURL, setSketchImageURL] = useState(imageNotFound);

  const {
    control,
    register,
    handleSubmit,
    errors,
    reset,
    setValue,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      clientName: "",
      fullAddress: "",
      inspectionDate: "",
      outsideTemperature: "",
      outsideHumidity: "",
      outsideConditions: "",
      frontHouseImage: "",
      sketchImage: "",
      structureType: "",
      claim: "",
      preparedFor: "",
      inspectedBy: "",
    },
  });
  const todateDate = new Date();
  const todaydd = String(todateDate.getDate()).padStart(2, '0');
  const todaymm = String(todateDate.getMonth() + 1).padStart(2, '0'); //January is 0!
  const todayyyyy = todateDate.getFullYear();

  const formattedToday = todayyyyy + '-' + todaymm + '-' + todaydd ;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      const result = await populateStepData(0, customerId, assignmentId);
      reset({
        clientName: result.clientName || "",
        fullAddress: result.fullAddress || "",
        inspectionDate: result.inspectionDate || formattedToday,
        outsideTemperature: result.outsideTemperature || "",
        outsideHumidity: result.outsideHumidity || "",
        outsideConditions: result.outsideConditions || "",
        frontHouseImage: result.frontHouseImage || "",
        sketchImage: result.sketchImage || "",
        structureType: result.structureType || "",
        claim: result.claim || "",
        preparedFor: result.preparedFor || "",
        inspectedBy: result.inspectedBy || "",
      });
      setFrontHouseImageURL(result.frontHouseImage || imageNotFound);
      setSketchImageURL(result.sketchImage || imageNotFound);
      setIsLoading(false);
    };
    if (customerId) {
      fetchData();
    }
  }, [customerId, assignmentId, reset]);

  const outsideConditionOptions = [
    { value: '', label: 'Select an Option' },
    { value: 'Clear', label: 'Clear' },
    { value: 'Partly Cloudy', label: 'Partly Cloudy' },
    { value: 'Overcast', label: 'Overcast' },
    { value: 'Light Rain', label: 'Light Rain' },
    { value: 'Rain', label: 'Rain' },
    { value: 'Storming', label: 'Storming' },
    { value: 'Snow', label: 'Snow' },
  ];

  const structureTypeOptions = [
    { value: '', label: 'Select an Option' },
    { value: 'Single-family Home', label: 'Single-family Home' },
    { value: 'Apartment', label: 'Apartment' },
    { value: 'Town-house', label: 'Town-house' },
    { value: 'Commercial Building', label: 'Commercial Building' },
  ];
  const { activeStep, handleBack, handleNext } = props;
  const onSubmit = async (data) => {
    data.customerId = customerId;
    data.assignmentId = assignmentId;
    await saveStepData(0, data);
    handleNext();
  };

  const handleHouseFrontFilesDrop = async (file) => {
    const uploadResponse = await uploadAssignmentImage(file);
    setValue('frontHouseImage', uploadResponse.fileUrl);
    setFrontHouseImageURL(uploadResponse.fileUrl);
  };

  const handleHouseFrontFilesDelete = async (file) => {
    setValue('frontHouseImage', '');
    setFrontHouseImageURL(imageNotFound);
  };

  const handleSketchImageFilesDrop = async (file) => {
    const uploadResponse = await uploadAssignmentImage(file);
    setValue("sketchImage", uploadResponse.fileUrl);
    setSketchImageURL(uploadResponse.fileUrl);
  };

  const handleSketchImageFilesDelete = async (file) => {
    setValue("sketchImage", "");
    setSketchImageURL(imageNotFound);
  };

  if (isLoading) return <CircularProgress />;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <GridContainer>
        <GridItem xs={12} sm={6} md={6}>
          <CustomInput
            labelText="Inspected By"
            id="inspectedBy-input"
            inputProps={{
              name: "inspectedBy",
              inputRef: register,
            }}
            formControlProps={{
              fullWidth: true,
            }}
          />
          <CustomInput
            labelText="Client Name"
            id="client-name-input"
            inputProps={{
              name: "clientName",
              inputRef: register,
            }}
            formControlProps={{
              fullWidth: true,
            }}
            error={!!errors.clientName}
            errorMessage={errors.clientName ? "Client name is required." : ""}
          />
          <CustomInput
            labelText="Address"
            id="full-address-input"
            inputProps={{
              name: "fullAddress",
              inputRef: register, // ({ required: true, maxLength: 200 })
            }}
            formControlProps={{
              fullWidth: true,
            }}
            error={!!errors.fullAddress}
            errorMessage={
              errors.fullAddress && errors.fullAddress.type === "required"
                ? "Address is required."
                : "Maximum address length is 200."
            }
          />
          <CustomInput
            labelText="Inspection Date"
            id="inspectionDate-input"
            inputProps={{
              type: "date",
              name: "inspectionDate",
              inputRef: register,
            }}
            formControlProps={{
              fullWidth: true,
            }}
          />
          <CustomInput
            labelText="Outside Temperature"
            id="outsideTemperature-input"
            inputProps={{
              type: "number",
              name: "outsideTemperature",
              inputRef: register,
            }}
            formControlProps={{
              fullWidth: true,
            }}
          />
          <CustomInput
            labelText="Outside Humidity"
            id="outsideHumidity-input"
            inputProps={{
              type: "number",
              name: "outsideHumidity",
              inputRef: register,
            }}
            formControlProps={{
              fullWidth: true,
            }}
          />
          <CustomInput
            labelText="Outside Conditions"
            id="outsideConditions-input"
            inputType="select"
            selectOptions={outsideConditionOptions}
            inputProps={{
              name: "outsideConditions",
              control,
            }}
            formControlProps={{
              fullWidth: true,
            }}
          />
          <CustomInput
            labelText="Structure Type"
            id="structureType-input"
            inputType="select"
            selectOptions={structureTypeOptions}
            inputProps={{
              name: "structureType",
              control,
            }}
            formControlProps={{
              fullWidth: true,
            }}
          />
          <CustomInput
            labelText="Claim"
            id="claim-input"
            inputProps={{
              name: "claim",
              inputRef: register,
            }}
            formControlProps={{
              fullWidth: true,
            }}
          />
          <CustomInput
            labelText="Prepared For"
            id="preparedFor-input"
            inputProps={{
              name: "preparedFor",
              inputRef: register,
            }}
            formControlProps={{
              fullWidth: true,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
          <Card>
            <CardActionArea>
              <CardContent>
                <FormLabel>Front House Image</FormLabel>
                <input type="hidden" name="frontHouseImage" ref={register} />

                <DropzoneArea
                  acceptedFiles={["image/*"]}
                  filesLimit={1}
                  maxFileSize={6000000}
                  onDrop={handleHouseFrontFilesDrop}
                  onDelete={handleHouseFrontFilesDelete}
                  // showPreviewsInDropzone={false}
                  initialFiles={
                    frontHouseImageURL === imageNotFound
                      ? []
                      : [frontHouseImageURL]
                  }
                />
              </CardContent>
            </CardActionArea>
          </Card>

          <Card>
            <CardActionArea>
              <CardContent>
                <FormLabel>Sketch Image</FormLabel>
                <input type="hidden" name="sketchImage" ref={register} />
                <DropzoneArea
                  acceptedFiles={["image/*"]}
                  filesLimit={1}
                  maxFileSize={6000000}
                  onDrop={handleSketchImageFilesDrop}
                  onDelete={handleSketchImageFilesDelete}
                  initialFiles={
                    sketchImageURL === imageNotFound ? [] : [sketchImageURL]
                  }
                />
              </CardContent>
            </CardActionArea>
          </Card>
        </GridItem>
      </GridContainer>

      <Button
        disabled={activeStep === 0}
        onClick={handleBack}
        className={classes.button}
      >
        Back
      </Button>
      <Button type="submit" disabled={isSubmitting} color="primary">
        Save and Next
      </Button>
    </form>
  );
}
