import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

// import styles
import ReactSummernote from 'react-summernote';
import 'react-summernote/dist/react-summernote.css';
import 'bootstrap/js/src/modal';
import 'bootstrap/js/src/dropdown';
import 'bootstrap/js/src/tooltip';
import 'bootstrap/dist/css/bootstrap.css';
import 'paper-css/paper.css';
import 'assets/css/printstyles.min.css';

// services
import authService from 'components/api-authorization/AuthorizeService';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Fab from '@material-ui/core/Fab';
import CircularProgress from '@material-ui/core/CircularProgress';

// @material-ui/icon components
import SaveIcon from '@material-ui/icons/Save';
import FindInPageIcon from '@material-ui/icons/FindInPage';

// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';

const styles = {
  fabMargin: {
    margin: '2px',
  },
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0',
    },
    '& a,& a:hover,& a:focus': {
      color: '#FFFFFF',
    },
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
};

const useStyles = makeStyles(styles);

const populateData = async (assignmentId, reportId) => {
  await authService.validateAccessToken();
  const response = await fetch(`reports/${assignmentId}/${reportId}`, {
    
  });
  const text = await response.json();
  return text;
};

const saveReportResult = async (assignmentId, reportId, type, content) => {
  await authService.validateAccessToken();
  const body = JSON.stringify(content);
  let myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  
  const request = await fetch(
    `reports/save/${assignmentId}/${reportId}/${type}`,
    {
      headers: myHeaders,
      method: 'POST',
      body,
    }
  );
  const data = await request.json();
  return data;
};

export default function ReportEditor() {
  const classes = useStyles();
  const { assignmentId, reportId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [editorContent, setEditorContent] = useState('');
  const [customerId, setCustomerId] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      document.body.className += ' A4';
      setIsLoading(true);

      const reportDetails = await populateData(assignmentId, reportId);
      setEditorContent(reportDetails.content);

      setCustomerId(reportDetails.customerId);
      setIsLoading(false);
    };
    fetchData();
    return function cleanup() {
      document.body.className = document.body.className.replace(' A4', '');
    };
  }, [assignmentId, reportId]);

  const onEditorChange = (content) => {
    setEditorContent(content);
  };

  const onSaveReport = async () => {
    await saveReportResult(assignmentId, reportId, 'final', editorContent);
  };
  const onPreviewReport = async () => {
    const fileDetails = await saveReportResult(
      assignmentId,
      reportId,
      'preview',
      editorContent
    );
    window.open(fileDetails.fileUrl);
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <GridContainer>
              <GridItem xs={12} sm={8} md={9}>
                <h4 className={classes.cardTitleWhite}>Report Editor</h4>
              </GridItem>
              <GridItem xs={12} sm={4} md={3}>
                <Tooltip title="Preview Report" aria-label="Preview Report">
                  <Fab
                    size="small"
                    variant="extended"
                    color="primary"
                    className={classes.fabMargin}
                    onClick={onPreviewReport}
                  >
                    <FindInPageIcon /> Preview
                  </Fab>
                </Tooltip>
                <Tooltip title="Save Report" aria-label="Save Report">
                  <Fab
                    size="small"
                    variant="extended"
                    color="primary"
                    className={classes.fabMargin}
                    onClick={onSaveReport}
                  >
                    <SaveIcon /> Save
                  </Fab>
                </Tooltip>
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <ReactSummernote
                value={editorContent}
                options={{
                  height: 900,
                  dialogsInBody: true,
                  toolbar: [
                    ['style', ['style']],
                    ['font', ['bold', 'italic', 'underline', 'clear']],
                    ['para', ['ul', 'ol', 'paragraph']],
                    // ['insert', ['link', 'picture']],
                    ['view', ['codeview']],
                  ],
                }}
                onChange={onEditorChange}
              />
            )}
          </CardBody>
          <CardFooter>
            <Link
              to={`/admin/reports/${customerId}/${assignmentId}`}
              color="secondary"
            >
              Go Back
            </Link>
          </CardFooter>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
