import React, { useState } from 'react';
import PropTypes from 'prop-types';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
// @material-ui/icons
import Clear from '@material-ui/icons/Clear';
import Check from '@material-ui/icons/Check';
// core components
import styles from 'assets/jss/material-dashboard-react/components/customInputStyle.js';

const useStyles = makeStyles(styles);

export default function CustomCheckbox(props) {
  const classes = useStyles();
  const {
    labelText,
    error,
    errorMessage,
    success,
    defaultValue = false,
    checkboxProps,
    formControlProps,
  } = props;

  const [isChecked, setIsChecked] = useState(defaultValue);

  return (
    <>
      <FormControlLabel
        {...formControlProps}
        control={
          <Checkbox
            {...checkboxProps}
            checked={isChecked}
            onChange={() => setIsChecked(!isChecked)}
          />
        }
        label={labelText}
      />
      {error ? (
        <>
          <Clear className={classes.feedback + ' ' + classes.labelRootError} />
          <p className={classes.labelRootError}> {errorMessage}</p>
        </>
      ) : success ? (
        <Check className={classes.feedback + ' ' + classes.labelRootSuccess} />
      ) : null}
    </>
  );
}

CustomCheckbox.propTypes = {
  id: PropTypes.string,
  checkboxProps: PropTypes.object,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  success: PropTypes.bool,
  defaultValue: PropTypes.bool,
};
