import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';

// services
import authService from 'components/api-authorization/AuthorizeService';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Fab from '@material-ui/core/Fab';

// @material-ui/icon components
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';

// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import MaterialTable from 'material-table';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';

const styles = {
  fabMargin: {
    margin: '2px',
  },
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0',
    },
    '& a,& a:hover,& a:focus': {
      color: '#FFFFFF',
    },
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
};

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const populateData = async (query) => {
  var queryString = Object.keys(query)
    .map((key) => key + '=' + query[key])
    .join('&');
  await authService.validateAccessToken();
  const response = await fetch(`assignments?${queryString}`, {});
  const data = await response.json();
  return {
    data: data.data,
    page: data.page,
    totalCount: data.totalCount,
    loading: false,
  };
};

const deleteData = async (customerId, assignmentId) => {
  await authService.validateAccessToken();
  let myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  const request = await fetch(`assignments/${customerId}/${assignmentId}`, {
    headers: myHeaders,
    method: 'DELETE',
  });
  const data = await request.text();
  return data;
};

export default function AssignmentsList() {
  const classes = useStyles();
  const { url } = useRouteMatch();
  const tableRef = React.createRef();

  const [open, setOpen] = React.useState(false);
  const [selectedToDelete, setSelectedToDelete] = React.useState({});

  const handleDeleteClickOpen = async (customerId, assignmentId, e) => {
    setSelectedToDelete({ customerId, assignmentId });
    setOpen(true);
  };

  const handleDeleteClickClose = () => {
    setSelectedToDelete({});
    setOpen(false);
  };

  const handleDeleteClickAction = async () => {
    await deleteData(
      selectedToDelete.customerId,
      selectedToDelete.assignmentId
    );
    setOpen(false);
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <GridContainer>
              <GridItem xs={12} sm={8} md={9}>
                <h4 className={classes.cardTitleWhite}>Assignments</h4>
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <MaterialTable
              options={{
                draggable: false,
                pageSize: 10,
                debounceInterval: 1000,
              }}
              tableRef={tableRef}
              columns={[
                {
                  title: 'Client Name',
                  render: (rowData) => <div>{rowData.clientName}</div>,
                },
                {
                  title: 'Address',
                  render: (rowData) => <div>{rowData.fullAddress}</div>,
                },
                { title: 'Inspection Date', field: 'inspectionDate' },
                { title: 'Completed', field: 'isCompleted' },
                { title: 'Complettion Date', field: 'completedOnDate' },
                {
                  title: '',
                  render: (rowData) => (
                    <div style={{ width: '200px' }}>
                      <Link
                        to={`${url}/details/${rowData.customerId}/${rowData.assignmentId}`}
                      >
                        <Tooltip
                          title="Edit Assignment"
                          aria-label="Edit Assignment"
                        >
                          <Fab
                            size="small"
                            color="primary"
                            className={classes.fabMargin}
                          >
                            <EditIcon />
                          </Fab>
                        </Tooltip>
                      </Link>
                      <Link
                        to={`/admin/reports/${rowData.customerId}/${rowData.assignmentId}`}
                      >
                        <Tooltip title="Reports" aria-label="Reports">
                          <Fab
                            size="small"
                            color="primary"
                            className={classes.fabMargin}
                          >
                            <DescriptionOutlinedIcon />
                          </Fab>
                        </Tooltip>
                      </Link>
                      &nbsp; &nbsp; &nbsp;
                      <Tooltip title="Delete" aria-label="Delete">
                        <Fab
                          onClick={(e) =>
                            handleDeleteClickOpen(
                              rowData.customerId,
                              rowData.assignmentId,
                              e
                            )
                          }
                          size="small"
                          color="secondary"
                          className={classes.fabMargin}
                        >
                          <DeleteIcon />
                        </Fab>
                      </Tooltip>
                    </div>
                  ),
                },
              ]}
              data={(query) => populateData(query)}
              actions={[
                {
                  icon: 'refresh',
                  tooltip: 'Refresh Data',
                  isFreeAction: true,
                  onClick: () =>
                    tableRef.current && tableRef.current.onQueryChange(),
                },
              ]}
              title=""
            />
          </CardBody>
        </Card>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleDeleteClickClose}
          aria-labelledby="alert-dialog-delete-title"
          aria-describedby="alert-dialog-delete-description"
        >
          <DialogTitle id="alert-dialog-delete-title">Delete</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-delete-description">
              Are you sure you want to delete this assignment?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteClickClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteClickAction} color="secondary">
              Yes, Delete.
            </Button>
          </DialogActions>
        </Dialog>
      </GridItem>
    </GridContainer>
  );
}
