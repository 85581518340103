import React from 'react';


export default function Login() {
    if (typeof window !== 'undefined') {
        window.location.href = "/account/login";
    }

    return (
        <div></div>
    );
}
