import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";

import { v4 as uuidv4 } from "uuid";

// services
import authService from "components/api-authorization/AuthorizeService";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { Grid } from "@material-ui/core";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

const populateData = async (customerId) => {
  await authService.validateAccessToken();
  const response = await fetch(`customers/${customerId}`, {});
  const data = await response.json();
  return data;
};

const saveData = async (object) => {
  await authService.validateAccessToken();
  const body = JSON.stringify(object);
  let myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const request = await fetch(`customers/create`, {
    headers: myHeaders,
    method: "POST",
    body,
  });
  const data = await request.json();
  return data;
};

export default function CustomerDetails(props) {
  const classes = useStyles();
  const history = useHistory();
  const { customerId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    errors,
    reset,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      state: "Florida",
      country: "USA",
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const result = await populateData(customerId);
      reset({
        firstName: result.firstName || "",
        lastName: result.lastName || "",
        businessName: result.businessName || "",
        email: result.email || "",
        phoneNumber: result.phoneNumber || "",
        secondaryPhoneNumber: result.secondaryPhoneNumber || "",
        streetAddress: result.streetAddress || "",
        city: result.city || "",
        state: result.state || "",
        postalCode: result.postalCode || "",
        country: result.country || "",
      });

      setIsLoading(false);
    };
    if (customerId) {
      fetchData();
    }
  }, [customerId, reset]);

  const isNewCustomer = customerId === undefined;

  const handleSave = async (data) => {
    data.customerId = customerId;
    const modifiedEntity = await saveData(data);
    if (isNewCustomer) {
      history.push(`/admin/customers/details/${modifiedEntity.customerId}`);
    }
  };

  const handleSaveAndNext = async (data) => {
    data.customerId = customerId;
    const modifiedEntity = await saveData(data);
    history.push(
      `/admin/assignments/create/${modifiedEntity.customerId}/${uuidv4()}`
    );
  };

  const handleGoBack = async () => {
    history.push(`/admin/customers`);
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <form>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Customer Details</h4>
              </CardHeader>
              <CardBody>
                {isLoading ? (
                  <>
                    <CircularProgress />
                  </>
                ) : (
                  <>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText="First Name"
                          id="first-name"
                          inputProps={{
                            name: "firstName",
                            inputRef: register({ required: true }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          error={!!errors.firstName}
                          errorMessage={
                            errors.firstName ? "First Name is required." : ""
                          }
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText="Last Name"
                          id="last-name"
                          inputProps={{
                            name: "lastName",
                            inputRef: register,
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText="Business Name"
                          id="business-name"
                          inputProps={{
                            name: "businessName",
                            inputRef: register,
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText="Email address"
                          id="email-address"
                          inputProps={{
                            name: "email",
                            inputRef: register({
                              pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          error={!!errors.email}
                          errorMessage={
                            errors.email ? "Email address is not valid." : ""
                          }
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText="Phone Number"
                          id="phone-number"
                          inputProps={{
                            name: "phoneNumber",
                            inputRef: register,
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText="Secondary Phone Number"
                          id="secondary-phone-number"
                          inputProps={{
                            name: "secondaryPhoneNumber",
                            inputRef: register,
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                    </GridContainer>

                    <GridContainer>
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText="Address"
                          id="streetAddress"
                          inputProps={{
                            name: "streetAddress",
                            inputRef: register,
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={2}>
                        <CustomInput
                          labelText="City"
                          id="city"
                          inputProps={{
                            name: "city",
                            inputRef: register,
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={2}>
                        <CustomInput
                          labelText="State"
                          id="province-state"
                          inputProps={{
                            name: "state",
                            inputRef: register,
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={2}>
                        <CustomInput
                          labelText="Postal Code"
                          id="postal-code"
                          inputProps={{
                            name: "postalCode",
                            inputRef: register,
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={2}>
                        <CustomInput
                          labelText="Country"
                          id="country"
                          inputProps={{
                            name: "country",
                            inputRef: register,
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                  </>
                )}
              </CardBody>
              <CardFooter>
                <Grid container>
                  <Grid item xs={12} sm={4}>
                    <Button
                      type="button"
                      onClick={handleSubmit(handleSave)}
                      disabled={isSubmitting}
                      color="primary"
                    >
                      {isNewCustomer ? "Add New" : "Save"}
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Button
                      type="button"
                      onClick={handleSubmit(handleSaveAndNext)}
                      disabled={isSubmitting}
                      color="success"
                    >
                      {isNewCustomer
                        ? "Add and Create Assignment"
                        : "Save and Create Assignment"}
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Button
                      type="button"
                      onClick={handleGoBack}
                      disabled={isSubmitting}
                      color="info"
                    >
                      Go Back
                    </Button>
                  </Grid>
                </Grid>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
