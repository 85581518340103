import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useForm } from 'react-hook-form';

import {
  saveStepData,
  populateStepData,
  getAvailableTemplates,
  addNewReport,
} from 'services/dataServices.js';

import sampleTypeLabels from './sampleTypes.js';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';

// core components
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';
import CustomCheckbox from 'components/CustomInput/CustomCheckbox.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';

const useStyles = makeStyles(() => {});

export default function Step4(props) {
  const classes = useStyles();
  const history = useHistory();

  const { customerId, assignmentId, activeStep, handleBack } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [initialFormValues, setInitialFormValues] = useState({});

  const [samplesTaken, setSamplesTaken] = useState([]);
  const [templateList, setTemplateList] = useState([]);
  const [recommendedGuildelines, setRecommendedGuidelines] = useState([]);
  const [
    recommendedGuildelinesTitle,
    setRecommendedGuildelinesTitle,
  ] = useState('');

  const templateSpecificOptions = {
    'FireAssessmentReport.min.html': [
      'Remove drywall and infected materials 2 feet beyond last visible soot.',
      'Remove lower kitchen cabinets.',
      'Remove upper kitchen cabinets.',
      'Remove bathroom vanity.',
      'Remove carpet.',
      'Remove flooring.',
      'HEPA clean and sanitize remaining walls, floors, air handlers, vents, ducts, AC coils, AC returns, hard surfaces and fog with a fungicide.',
      'Depending on any hidden damage, more of the walls and baseboards may need to be removed.',
      'Other',
    ],
    'MoldAssessmentReport.min.html': [
      'Remove drywall and infected materials 2 feet beyond last visible mold.',
      'Remove lower kitchen cabinets.',
      'Remove upper kitchen cabinets.',
      'Remove bathroom vanity.',
      'Remove carpet.',
      'Remove flooring.',
      'HEPA clean and sanitize remaining walls, floors, air handlers, vents, ducts, AC coils, AC returns, hard surfaces and fog with a fungicide.',
      'Depending on any hidden damage, more of the walls and baseboards may need to be removed.',
      'Other',
    ],
    'BacteriaAssessmentReport.min.html': [
      'Remove any contaminated drywall and infected materials.',
      'Remove the tile floor in (INSERT LOCATION).',
      'HEPA clean and sanitize remaining walls, floors, air handlers, vents, ducts, AC coils, AC returns, hard surfaces and fog with a fungicide.',
      'Depending on any hidden damage, more of the walls and baseboards may need to be removed.',
      'Other',
    ],
    // dynamically filled
    'OfficialClearanceLetter.min.html': [],
  };

  const {
    control,
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { isSubmitting },
  } = useForm();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const step1SavedData = await populateStepData(
        1,
        customerId,
        assignmentId
      );

      const step4SavedData = await populateStepData(
        4,
        customerId,
        assignmentId
      );

      const step1SamplesData = step1SavedData.items
        .filter((item) => item.sampleType !== 'AdditionalPhoto')
        .map((item, sampleIndex) => ({
          name: item.sampleType,
          label: `${item.description} (${sampleTypeLabels[item.sampleType]})`,
          selectedValue:
            step4SavedData?.sampleAssessments?.[sampleIndex]?.assessment ||
            false,
        }));

      setSamplesTaken(step1SamplesData);
      populateRecommendedGuidelines(
        step4SavedData.templateId || 'select-a-template'
      );
      setInitialFormValues(step4SavedData);

      const templateListResult = await getAvailableTemplates();
      templateListResult.push({
        templateId: 'select-a-template',
        name: 'Select a Template',
        description: 'Select a Template',
      });
      setTemplateList(
        templateListResult.map((t) => ({
          value: t.templateId,
          label: t.name,
          description: t.description,
        }))
      );

      setIsLoading(false);
    };
    if (customerId && assignmentId) {
      fetchData();
    }
  }, [customerId, assignmentId, reset]);

  const onHandleSaveAndExit = async (data) => {
    data.customerId = customerId;
    data.assignmentId = assignmentId;
    await saveStepData(4, data);
    history.push('/admin/assignments');
  };

  const onHandleSaveAndCreateReport = async (data) => {
    data.customerId = customerId;
    data.assignmentId = assignmentId;

    await saveStepData(4, data);
    const templateId = getValues('templateId');
    const selectedTemplate = templateList.find((t) => t.value === templateId);
    const reportData = {
      customerId,
      assignmentId,
      templateId,
      name: selectedTemplate.label,
      description: selectedTemplate.description,
    };

    const newReport = await addNewReport(reportData);

    history.push(`/admin/reports/editor/${assignmentId}/${newReport.reportId}`);
  };

  const populateRecommendedGuidelines = (selectedTemplate) => {
    if (selectedTemplate === 'select-a-template') {
      setRecommendedGuidelines([]);
      setRecommendedGuildelinesTitle('');
    } else if (selectedTemplate === 'OfficialClearanceLetter.min.html') {
      setRecommendedGuidelines(
        samplesTaken.map((ssd) => {
          return ssd.label.substring(0, ssd.label.indexOf("("));
        })
      );
      
      setRecommendedGuildelinesTitle('Areas that Cleared:');
    } else {
      setRecommendedGuidelines(templateSpecificOptions[selectedTemplate]);
      setRecommendedGuildelinesTitle('Recommended Guidelines:');
    }
  };

  const handleTemplateSelectorChange = ([selected]) => {
    populateRecommendedGuidelines(selected.target.value);
    return selected;
  };

  if (isLoading) return <CircularProgress />;

  return (
    <form>
      <label>STEP 4</label>
      <GridContainer>
        <GridItem xs={12} sm={6} md={6}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">
              Check if results are <b>Positive</b>
            </FormLabel>
            <FormGroup>
              {samplesTaken.map((sample, sampleIndex) => (
                <div key={`div-${sample.label}-${sampleIndex}`}>
                  <input
                    key={`label-${sample.label}-${sampleIndex}`}
                    type="hidden"
                    name={`sampleAssessments[${sampleIndex}].label`}
                    value={sample.label}
                    ref={register}
                  />
                  <input
                    key={`name-${sample.label}-${sampleIndex}`}
                    type="hidden"
                    name={`sampleAssessments[${sampleIndex}].name`}
                    value={sample.name}
                    ref={register}
                  />
                  <CustomCheckbox
                    key={`assessment-${sample.label}-${sampleIndex}`}
                    labelText={sample.label}
                    checkboxProps={{
                      name: `sampleAssessments[${sampleIndex}].assessment`,
                      color: "primary",
                      inputRef: register,
                    }}
                    // formControlProps={{
                    //   labelPlacement: 'start',
                    // }}
                    defaultValue={sample.selectedValue}
                  />
                </div>
              ))}
            </FormGroup>
          </FormControl>
          <CustomInput
            labelText="Completed On Date"
            id="completed-on-date-input"
            inputProps={{
              type: "date",
              name: "completedOnDate",
              inputRef: register,
              defaultValue: initialFormValues.completedOnDate,
            }}
            formControlProps={{
              fullWidth: true,
            }}
          />
          <CustomCheckbox
            labelText="Is Completed"
            checkboxProps={{
              name: "isCompleted",
              color: "primary",
              inputRef: register,
              defaultValue: initialFormValues.isCompleted,
            }}
            // formControlProps={{
            //   labelPlacement: 'start',
            // }}
            defaultValue={initialFormValues["isCompleted"]}
          />
          <CustomInput
            labelText="ُSelect Report Template"
            id="template-input"
            inputType="select"
            selectOptions={templateList}
            inputProps={{
              name: "templateId",
              control,
              defaultValue: initialFormValues.templateId || "select-a-template",
              onChange: handleTemplateSelectorChange,
            }}
            formControlProps={{
              fullWidth: true,
            }}
          />
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">
              {recommendedGuildelinesTitle}
            </FormLabel>
            <FormGroup>
              {recommendedGuildelines.map(
                (recommendedGuideline, guidelineIndex) => {
                  if (recommendedGuideline === "Other") {
                    // find other name:
                    let defultOtherName = "";
                    const hasOtherDescription =
                      initialFormValues.recommendedGuidelines
                        ?.map((ifv) => ifv.name)
                        .filter((n) => !recommendedGuildelines.includes(n));
                    if (hasOtherDescription.length === 1) {
                      defultOtherName = hasOtherDescription[0];
                    }

                    return (
                      <div key={`div-guideline-${guidelineIndex}`}>
                        <CustomCheckbox
                          labelText={recommendedGuideline}
                          checkboxProps={{
                            name: `recommendedGuidelines[${guidelineIndex}].value`,
                            color: "primary",
                            inputRef: register,
                          }}
                          defaultValue={
                            initialFormValues.recommendedGuidelines?.find(
                              (rg) => rg.name === recommendedGuideline
                            )?.value || false
                          }
                        />
                        <CustomInput
                          labelText="ُSpecial recommendations"
                          id="special-recommendations-input"
                          inputProps={{
                            name: `recommendedGuidelines[${guidelineIndex}].name`,
                            inputRef: register,
                            defaultValue: defultOtherName,
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </div>
                    );
                  } else {
                    return (
                      <div key={`div-guideline-${guidelineIndex}`}>
                        <input
                          type="hidden"
                          name={`recommendedGuidelines[${guidelineIndex}].name`}
                          value={recommendedGuideline}
                          ref={register}
                        />
                        <CustomCheckbox
                          labelText={recommendedGuideline}
                          checkboxProps={{
                            name: `recommendedGuidelines[${guidelineIndex}].value`,
                            color: "primary",
                            inputRef: register,
                          }}
                          defaultValue={
                            initialFormValues.recommendedGuidelines?.find(
                              (rg) => rg.name === recommendedGuideline
                            )?.value || true
                          }
                        />
                      </div>
                    );
                  }
                }
              )}
            </FormGroup>
          </FormControl>
        </GridItem>
      </GridContainer>

      <Button
        disabled={activeStep === 0}
        onClick={handleBack}
        className={classes.button}
      >
        Back
      </Button>

      <Button
        type="button"
        onClick={handleSubmit(onHandleSaveAndCreateReport)}
        disabled={isSubmitting}
        color="primary"
      >
        Save and Create Report
      </Button>
      <Button
        type="button"
        onClick={handleSubmit(onHandleSaveAndExit)}
        disabled={isSubmitting}
        color="primary"
      >
        Save and Exit
      </Button>
    </form>
  );
}
