import React from 'react';
import { useParams, Link } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';

import Card from '@material-ui/core/Card';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';

// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';

// page components
import Step0 from 'views/Assignments/AssignmentSteps/Step0';
import Step1 from 'views/Assignments/AssignmentSteps/Step1';
import Step2 from 'views/Assignments/AssignmentSteps/Step2';
import Step3 from 'views/Assignments/AssignmentSteps/Step3';
import Step4 from 'views/Assignments/AssignmentSteps/Step4';

const useStyles = makeStyles((theme) => ({
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
}));

export default function AssignmentDetails() {
  const classes = useStyles();
  const { customerId, assignmentId } = useParams();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = [
    'Start Inspection',
    'Add Pictures & Collect Samples',
    'General Observations',
    'Lab Results',
    'Finalize',
  ];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const getStepContent = (
    step,
    activeStep,
    handleBack,
    handleNext,
    customerId,
    assignmentId
  ) => {
    switch (step) {
      case 0:
        return (
          <Step0
            activeStep={activeStep}
            handleBack={handleBack}
            handleNext={handleNext}
            customerId={customerId}
            assignmentId={assignmentId}
          />
        );
      case 1:
        return (
          <Step1
            activeStep={activeStep}
            handleBack={handleBack}
            handleNext={handleNext}
            customerId={customerId}
            assignmentId={assignmentId}
          />
        );
      case 2:
        return (
          <Step2
            activeStep={activeStep}
            handleBack={handleBack}
            handleNext={handleNext}
            customerId={customerId}
            assignmentId={assignmentId}
          />
        );
      case 3:
        return (
          <Step3
            activeStep={activeStep}
            handleBack={handleBack}
            handleNext={handleNext}
            customerId={customerId}
            assignmentId={assignmentId}
          />
        );
      case 4:
        return (
          <Step4
            activeStep={activeStep}
            handleBack={handleBack}
            handleNext={handleNext}
            customerId={customerId}
            assignmentId={assignmentId}
          />
        );
      default:
        return (
          <Step0
            activeStep={activeStep}
            handleBack={handleBack}
            handleNext={handleNext}
            customerId={customerId}
            assignmentId={assignmentId}
          />
        );
    }
  };
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Assignment Details</h4>
            </CardHeader>
            <CardBody>
              <div>
                <Stepper alternativeLabel nonLinear activeStep={activeStep}>
                  {steps.map((label, index) => {
                    const stepProps = {};
                    const buttonProps = {};
                    return (
                      <Step key={label} {...stepProps}>
                        <StepButton
                          onClick={handleStep(index)}
                          {...buttonProps}
                        >
                          {label}
                        </StepButton>
                      </Step>
                    );
                  })}
                </Stepper>
                <div>
                  {getStepContent(
                    activeStep,
                    activeStep,
                    handleBack,
                    handleNext,
                    customerId,
                    assignmentId
                  )}
                </div>
              </div>
            </CardBody>
            <CardFooter>
              <Link to="/admin/assignments" color="secondary">
                Go Back
              </Link>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
