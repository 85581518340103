import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { DropzoneArea } from 'material-ui-dropzone';

import {
  saveStepData,
  populateStepData,
  uploadAssignmentImage,
} from 'services/dataServices.js';

import sampleTypeLabels from './sampleTypes.js';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import CardMedia from '@material-ui/core/CardMedia';
import Paper from '@material-ui/core/Paper';

// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';

const useStyles = makeStyles((theme) => ({
  media: {
    width: '100%',
    height: 200,
  },
  paper: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  dropzoneArea: {
    margin: theme.spacing(2),
  },
}));

export default function Step1(props) {
  const classes = useStyles();
  const {
    customerId,
    assignmentId,
    activeStep,
    handleBack,
    handleNext,
  } = props;

  const [isLoading, setIsLoading] = useState(false);

  const [sampleImageFiles, setSampleImageFiles] = useState([]);

  const {
    control,
    register,
    handleSubmit,
    // errors,
    reset,
    getValues,
    formState: { isSubmitting },
  } = useForm();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const result = await populateStepData(1, customerId, assignmentId);
      setSampleImageFiles(result.items);
      // reset({ items: result.items || [] });
      setIsLoading(false);
    };
    if (customerId && assignmentId) {
      fetchData();
    }
  }, [customerId, assignmentId]);

  const handleSampleImageFilesDrop = async (file) => {
    const uploadResponse = await uploadAssignmentImage(file);
    file.fileName = uploadResponse.fileName;
    setSampleImageFiles((prev) => [
      ...prev,
      {
        fileName: uploadResponse.fileName,
        fileUrl: uploadResponse.fileUrl,
        sampleType: '',
        description: '',
      },
    ]);
  };

  const handleSampleImageFilesDelete = async (file) => {
    if (!file.fileName) {
      // if dropzone files where initialized with server data.
      file.fileName = file.name;
    }
    const filteredSampleFiles = sampleImageFiles.filter(
      (i) => !file.fileName.includes(i.fileName)
    );
    // maintain form state instead of resetting the whole form
    const formValues = getValues({ nest: true });
    const filteredFormValues = formValues.items.filter(
      (i) => !file.fileName.includes(i.fileName)
    );
    reset({ items: filteredFormValues || [] });
    setSampleImageFiles(filteredSampleFiles);
  };

  const sampleTypes = [
    { value: '', label: 'Select an Option' },
    { value: 'AirSample', label: sampleTypeLabels['AirSample'] },
    { value: 'ControlSample', label: sampleTypeLabels['ControlSample'] },
    { value: 'GeneralAirSample', label: sampleTypeLabels['GeneralAirSample'] },
    {
      value: 'InWallCavitySample',
      label: sampleTypeLabels['InWallCavitySample'],
    },
    { value: 'Swab', label: sampleTypeLabels['Swab'] },
    { value: 'TapeLift', label: sampleTypeLabels['TapeLift'] },
    { value: 'AdditionalPhoto', label: sampleTypeLabels['AdditionalPhoto'] },
  ];

  const onSubmit = async (data) => {
    data.customerId = customerId;
    data.assignmentId = assignmentId;
    await saveStepData(1, data);
    handleNext();
  };

  if (isLoading) return <CircularProgress />;
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <label>STEP 1</label>
      <Paper className={classes.dropzoneArea} elevation={3}>
        <DropzoneArea
          acceptedFiles={['image/*']}
          filesLimit={49}
          maxFileSize={6000000}
          onDrop={handleSampleImageFilesDrop}
          onDelete={handleSampleImageFilesDelete}
          initialFiles={sampleImageFiles.map((s) => s.fileUrl)}
        />
      </Paper>
      <GridContainer justify="center" spacing={3}>
        {sampleImageFiles.map((sampleImage, index) => (
          <GridItem xs={12} sm={6} md={4} key={`sample-img-${index}`}>
            <Paper className={classes.paper} elevation={3}>
              <GridContainer>
                <GridItem xs={4}>
                  <CardMedia
                    className={classes.media}
                    image={sampleImage.fileUrl}
                    title={sampleImage.description}
                  />
                </GridItem>
                <GridItem xs={8}>
                  <input
                    type="hidden"
                    name={`items[${index}].fileName`}
                    value={sampleImage.fileName}
                    ref={register}
                  />
                  <input
                    type="hidden"
                    name={`items[${index}].fileUrl`}
                    value={sampleImage.fileUrl}
                    ref={register}
                  />
                  <CustomInput
                    labelText="Description"
                    id={`description-${sampleImage.fileName}`}
                    inputProps={{
                      name: `items[${index}].description`,
                      inputRef: register,
                      defaultValue: `${sampleImage.description}`,
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                  <CustomInput
                    labelText="Sample Type"
                    id={`sampletType-input-${index}`}
                    inputType="select"
                    selectOptions={sampleTypes}
                    inputProps={{
                      name: `items[${index}].sampleType`,
                      control,
                      defaultValue: `${sampleImage.sampleType}`,
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
              </GridContainer>
            </Paper>
          </GridItem>
        ))}
      </GridContainer>

      <Button
        disabled={activeStep === 0}
        onClick={handleBack}
        className={classes.button}
      >
        Back
      </Button>
      <Button type="submit" disabled={isSubmitting} color="primary">
        Save and Next
      </Button>
    </form>
  );
}
