const sampleTypeLabels = {
  AirSample: 'Air Sample',
  ControlSample: 'Control Sample',
  GeneralAirSample: 'General Air Sample',
  InWallCavitySample: 'In-Wall Cavity Sample',
  Swab: 'Swab',
  TapeLift: 'Tape Lift',
  AdditionalPhoto: 'Additional Photo',
};

export default sampleTypeLabels;
