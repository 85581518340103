import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { saveStepData, populateStepData } from 'services/dataServices.js';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';

// core components
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';
import CustomCheckbox from 'components/CustomInput/CustomCheckbox.js';

const useStyles = makeStyles(() => {});

export default function Step2(props) {
  const classes = useStyles();
  const {
    customerId,
    assignmentId,
    activeStep,
    handleBack,
    handleNext,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [initialFormValues, setInitialFormValues] = useState({});

  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const result = await populateStepData(2, customerId, assignmentId);
      setInitialFormValues(result);
      reset(result);
      setIsLoading(false);
    };
    if (customerId && assignmentId) {
      fetchData();
    }
  }, [customerId, assignmentId, reset]);

  const onSubmit = async (data) => {
    data.customerId = customerId;
    data.assignmentId = assignmentId;
    await saveStepData(2, data);
    handleNext();
  };
  const generalObservationArray = [
    { label: 'Musty Smell or Odor', name: 'MustySmellorOdor' },
    { label: 'Water Damage', name: 'WaterDamage' },
    { label: 'Excess Humidity', name: 'ExcessHumidity' },
    { label: 'Excess Moisture', name: 'ExcessMoisture' },
    { label: 'Visual Growth', name: 'VisualGrowth' },
    { label: 'Roof Leak', name: 'RoofLeak' },
    { label: 'HVAC Problem', name: 'HVACProblem' },
    { label: 'Plumbing Issue', name: 'PlumbingIssue' },
    { label: 'Health Complications', name: 'HealthComplications' },
    { label: 'Localized Problem', name: 'LocalizedProblem' },
    { label: 'Widespread Problem', name: 'WidespreadProblem' },
    { label: 'Control Sample Taken', name: 'ControlSampleTaken' },
  ];

  if (isLoading) return <CircularProgress />;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <label>STEP 2</label>
      <CustomInput
        labelText="Indoor Temperature"
        id="indoorTemperature-input"
        inputProps={{
          name: "indoorTemperature",
          inputRef: register,
        }}
        formControlProps={{
          fullWidth: true,
        }}
      />
      <CustomInput
        labelText="Indoor Humidity"
        id="indoorHumidity-input"
        inputProps={{
          name: "indoorHumidity",
          inputRef: register,
        }}
        formControlProps={{
          fullWidth: true,
        }}
      />

      <FormControl component="fieldset" className={classes.formControl}>
        <FormLabel component="legend">General Observations</FormLabel>
        <FormGroup row>
          {generalObservationArray.map((goItem) => (
            <CustomCheckbox
              key={goItem.name}
              labelText={goItem.label}
              checkboxProps={{
                name: goItem.name,
                color: "primary",
                inputRef: register,
              }}
              defaultValue={
                initialFormValues[
                  goItem.name.charAt(0).toLowerCase() + goItem.name.substring(1)
                ]
              }
            />
          ))}
        </FormGroup>
      </FormControl>
      <CustomInput
        labelText="Number of Air Samples Taken"
        id="airSampleCount-input"
        inputProps={{
          type: "number",
          name: "airSampleCount",
          inputRef: register,
        }}
        formControlProps={{
          fullWidth: true,
        }}
      />
      <CustomInput
        labelText="Number of Swab Samples Taken"
        id="swabSampleCount-input"
        inputProps={{
          type: "number",
          name: "swabSampleCount",
          inputRef: register,
        }}
        formControlProps={{
          fullWidth: true,
        }}
      />
      <CustomInput
        labelText="Testing Cost"
        id="testingCost-input"
        inputProps={{
          name: "testingCost",
          inputRef: register,
        }}
        formControlProps={{
          fullWidth: true,
        }}
      />
      <CustomInput
        labelText="Payment Method"
        id="paymentMethod-input"
        inputProps={{
          name: "paymentMethod",
          inputRef: register,
        }}
        formControlProps={{
          fullWidth: true,
        }}
      />
      <CustomInput
        labelText="Inspector Observations"
        id="additionalNotes-input"
        inputProps={{
          name: "additionalNotes",
          multiline: true,
          rows: 4,
          inputRef: register,
        }}
        formControlProps={{
          fullWidth: true,
        }}
      />
      <Button
        disabled={activeStep === 0}
        onClick={handleBack}
        className={classes.button}
      >
        Back
      </Button>
      <Button type="submit" disabled={isSubmitting} color="primary">
        Save and Next
      </Button>
    </form>
  );
}
