import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';

// services
import authService from 'components/api-authorization/AuthorizeService';
import { getAvailableTemplates, addNewReport } from 'services/dataServices.js';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Fab from '@material-ui/core/Fab';

// @material-ui/icon components
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import RegenerateIcon from '@material-ui/icons/RestorePage';

// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import MaterialTable from 'material-table';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';

const styles = {
  fabMargin: {
    margin: '2px',
  },
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0',
    },
    '& a,& a:hover,& a:focus': {
      color: '#FFFFFF',
    },
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
};

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const populateData = async (assignmentId) => {
  await authService.validateAccessToken();
  const response = await fetch(`reports/${assignmentId}`, {});
  const data = await response.json();
  return data;
};

const regenerateData = async (
  assignmentId,
  reportId,
  customerId,
  templateId
) => {
  await authService.validateAccessToken();
  let myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');

  const request = await fetch(
    `reports/regenerate/${assignmentId}/${reportId}/${customerId}/${templateId}`,
    {
      headers: myHeaders,
      method: 'POST',
    }
  );
  const data = await request.text();
  return data;
};

const deleteData = async (assignmentId, reportId) => {
  await authService.validateAccessToken();
  let myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');

  const request = await fetch(`reports/${assignmentId}/${reportId}`, {
    headers: myHeaders,
    method: 'DELETE',
  });
  const data = await request.text();
  return data;
};

export default function ReportList() {
  const classes = useStyles();
  const { customerId, assignmentId } = useParams();

  const tableRef = React.createRef();

  const {
    register,
    handleSubmit,
    errors,
    control,
    reset,
    setValue,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      name: '',
      description: '',
    },
  });

  const [openRegenerate, setOpenRegenerate] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [openAddReport, setOpenAddReport] = React.useState(false);
  const [selectedToRegenerate, setSelectedToRegenerate] = React.useState({});
  const [selectedToDelete, setSelectedToDelete] = React.useState({});
  const [isTemplatesLoading, setIsTemplatesLoading] = React.useState(true);
  const [templateList, setTemplateList] = React.useState([]);
  const [tableData, setTableData] = React.useState([]);

  useEffect(() => {
    const fetchTemplates = async () => {
      setIsTemplatesLoading(true);
      const templateListResult = await getAvailableTemplates();
      setTemplateList(
        templateListResult.map((t) => ({
          value: t.templateId,
          label: t.name,
          description: t.description,
        }))
      );
      reset({
        templateId: templateListResult[0].templateId,
        name: templateListResult[0].name,
        description: templateListResult[0].description,
      });

      setIsTemplatesLoading(false);
    };

    fetchTemplates();
    loadReports();
  }, [assignmentId, reset]);

  const loadReports = async () => {
    const reportData = await populateData(assignmentId);
    setTableData(reportData);
  };

  const handleRegenerateClickOpen = async (
    assignmentId,
    reportId,
    customerId,
    templateId,
    e
  ) => {
    setSelectedToRegenerate({ assignmentId, reportId, customerId, templateId });
    setOpenRegenerate(true);
  };

  const handleRegenerateClickClose = () => {
    setSelectedToRegenerate({});
    setOpenRegenerate(false);
  };

  const handleRegenerateClickAction = async () => {
    await regenerateData(
      selectedToRegenerate.assignmentId,
      selectedToRegenerate.reportId,
      selectedToRegenerate.customerId,
      selectedToRegenerate.templateId
    );
    setOpenRegenerate(false);
  };

  const handleDeleteClickOpen = async (assignmentId, reportId, e) => {
    setSelectedToDelete({ assignmentId, reportId });
    setOpenDelete(true);
  };

  const handleDeleteClickClose = () => {
    setSelectedToDelete({});
    setOpenDelete(false);
  };

  const handleDeleteClickAction = async () => {
    await deleteData(selectedToDelete.assignmentId, selectedToDelete.reportId);
    setOpenDelete(false);
    await loadReports();
  };

  const handleAddReportClickOpen = async (e) => {
    setOpenAddReport(true);
  };

  const handleAddReportClickClose = () => {
    setOpenAddReport(false);
  };

  const handleAddReportClickAction = async (data) => {
    data.assignmentId = assignmentId;
    data.customerId = customerId;

    await addNewReport(data);
    await loadReports();
    setOpenAddReport(false);
  };

  const handleTemplateSelectorChange = ([selected]) => {
    const selectedTemplate = templateList.find(
      (t) => t.value === selected.target.value
    );
    setValue('name', selectedTemplate.label);
    setValue('description', selectedTemplate.description);
    return selected;
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <GridContainer>
              <GridItem xs={12} sm={8} md={9}>
                <h4 className={classes.cardTitleWhite}>Reports</h4>
              </GridItem>
              <GridItem xs={12} sm={4} md={3}>
                <Fab
                  size="small"
                  variant="extended"
                  color="primary"
                  className={classes.fabMargin}
                  onClick={handleAddReportClickOpen}
                >
                  <AddIcon /> Add New Report
                </Fab>
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <MaterialTable
              options={{
                draggable: false,
                pageSize: 10,
              }}
              tableRef={tableRef}
              columns={[
                {
                  title: 'Report Name',
                  field: 'name',
                  render: (rowData) => <div>{rowData.name}</div>,
                },
                {
                  title: 'Description',
                  field: 'description',
                  render: (rowData) => <div>{rowData.description}</div>,
                },
                {
                  title: '',
                  render: (rowData) => (
                    <div>
                      <Link
                        to={`/admin/reports/editor/${rowData.assignmentId}/${rowData.reportId}`}
                      >
                        <Tooltip title="Edit Report" aria-label="Edit Report">
                          <Fab
                            size="small"
                            color="primary"
                            className={classes.fabMargin}
                          >
                            <EditIcon />
                          </Fab>
                        </Tooltip>
                      </Link>
                      &nbsp; &nbsp; &nbsp;
                      <Tooltip title="Regenerate" aria-label="Regenerate">
                        <Fab
                          onClick={(e) =>
                            handleRegenerateClickOpen(
                              rowData.assignmentId,
                              rowData.reportId,
                              rowData.customerId,
                              rowData.templateId,
                              e
                            )
                          }
                          size="small"
                          color="secondary"
                          className={classes.fabMargin}
                        >
                          <RegenerateIcon />
                        </Fab>
                      </Tooltip>
                      <Tooltip title="Delete" aria-label="Delete">
                        <Fab
                          onClick={(e) =>
                            handleDeleteClickOpen(
                              rowData.assignmentId,
                              rowData.reportId,
                              e
                            )
                          }
                          size="small"
                          color="secondary"
                          className={classes.fabMargin}
                        >
                          <DeleteIcon />
                        </Fab>
                      </Tooltip>
                    </div>
                  ),
                },
              ]}
              data={tableData}
              title=""
            />
          </CardBody>
          <CardFooter>
            <Link to={`/admin/assignments`} color="secondary">
              Go Back
            </Link>
          </CardFooter>
        </Card>
        <Dialog
          open={openAddReport}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleAddReportClickClose}
          aria-labelledby="alert-dialog-add-title"
          aria-describedby="alert-dialog-add-description"
        >
          <form onSubmit={handleSubmit(handleAddReportClickAction)}>
            {isTemplatesLoading ? null : (
              <>
                <DialogTitle id="alert-dialog-add-title">
                  Add New Report
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-add-description">
                    Create a new report by selecting a template:
                  </DialogContentText>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      {/* <input
                    type="hidden"
                    name="templateId"
                    ref={register({ required: true })}
                  /> */}
                      <CustomInput
                        labelText="Template"
                        id="template-input"
                        inputType="select"
                        selectOptions={templateList}
                        inputProps={{
                          name: 'templateId',
                          control,
                          defaultValue: templateList[0].value,
                          onChange: handleTemplateSelectorChange,
                        }}
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText="Report Name"
                        id="name-input"
                        inputProps={{
                          name: 'name',
                          inputRef: register({ required: true }),
                        }}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        error={!!errors.firstName}
                        errorMessage={
                          errors.firstName ? 'Name is required.' : ''
                        }
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText="Description"
                        id="description-input"
                        inputProps={{
                          name: 'description',
                          inputRef: register,
                        }}
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={handleAddReportClickClose}
                    color="transparent"
                  >
                    Cancel
                  </Button>
                  <Button type="submit" disabled={isSubmitting} color="primary">
                    Create
                  </Button>
                </DialogActions>
              </>
            )}
          </form>
        </Dialog>
        <Dialog
          open={openRegenerate}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleRegenerateClickClose}
          aria-labelledby="alert-dialog-regenerate-title"
          aria-describedby="alert-dialog-regenerate-description"
        >
          <DialogTitle id="alert-dialog-regenerate-title">
            Regenerate
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-regenerate-description">
              Are you sure you want to regenerate this report?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleRegenerateClickClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleRegenerateClickAction} color="warning">
              Yes, Regenerate.
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openDelete}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleDeleteClickClose}
          aria-labelledby="alert-dialog-delete-title"
          aria-describedby="alert-dialog-delete-description"
        >
          <DialogTitle id="alert-dialog-delete-title">Delete</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-delete-description">
              Are you sure you want to delete this report?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteClickClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteClickAction} color="danger">
              Yes, Delete.
            </Button>
          </DialogActions>
        </Dialog>
      </GridItem>
    </GridContainer>
  );
}
