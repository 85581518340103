// services
import authService from 'components/api-authorization/AuthorizeService';

export const saveStepData = async (stepId, payload) => {
  await authService.validateAccessToken();
  const body = JSON.stringify(payload);
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  const request = await fetch(`assignments/saveStep/${stepId}`, {
    headers: myHeaders,
    method: 'POST',
    body,
  });
  const data = await request.json();
  return data;
};

export const populateStepData = async (stepId, customerId, assignmentId) => {
  await authService.validateAccessToken();
  const response = await fetch(
    `assignments/${stepId}/${customerId}/${assignmentId || ''}`,
    {}
  );
  const data = await response.json();
  return data;
};

export const uploadAssignmentFile = async (file) => {
  const formData = new FormData();
  formData.append('file', file);
  const myHeaders = new Headers();
  await authService.validateAccessToken();

  const fileUploadRequest = await fetch('assignments/UploadAssignmentFiles', {
    headers: myHeaders,
    method: 'POST',
    body: formData,
  });
  const uploadResponse = await fileUploadRequest.json();
  return uploadResponse;
};

export const uploadAssignmentImage = async (file) => {
  const formData = new FormData();
  formData.append('file', file);
  const myHeaders = new Headers();
  await authService.validateAccessToken();

  const fileUploadRequest = await fetch('assignments/UploadAssignmentImages', {
    headers: myHeaders,
    method: 'POST',
    body: formData,
  });
  const uploadResponse = await fileUploadRequest.json();
  return uploadResponse;
};

export const getAvailableTemplates = async () => {
  await authService.validateAccessToken();
  const response = await fetch(`templates`, {});
  const data = await response.json();
  return data;
};

export const addNewReport = async (payload) => {
  await authService.validateAccessToken();
  const body = JSON.stringify(payload);
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');

  const request = await fetch(`reports/create`, {
    headers: myHeaders,
    method: 'POST',
    body,
  });
  const data = await request.json();
  return data;
};
