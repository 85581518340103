import "assets/css/material-dashboard-react.css?v=1.8.0";

import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";

import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';

import Admin from "layouts/Admin.js";
import Login from "views/Account/Login";


//import registerServiceWorker from './registerServiceWorker';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist} basename={baseUrl}>
    <Switch>
      <AuthorizeRoute path="/admin" component={Admin} />
      <Route path="/account/login" component={Login}/>
      <Redirect from="/" to="/admin" />
    </Switch>
  </Router>,
  rootElement);

// Uncomment the line above that imports the registerServiceWorker function
// and the line below to register the generated service worker.
// By default create-react-app includes a service worker to improve the
// performance of the application by caching static assets. This service
// worker can interfere with the Identity UI, so it is
// disabled by default when Identity is being used.
//
//registerServiceWorker();

