import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
// @material-ui/icons
import Clear from '@material-ui/icons/Clear';
import Check from '@material-ui/icons/Check';
// core components
import styles from 'assets/jss/material-dashboard-react/components/customInputStyle.js';

const useStyles = makeStyles(styles);

export default function CustomInput(props) {
  const classes = useStyles();
  const {
    formControlProps,
    labelText,
    id,
    labelProps,
    inputProps,
    error,
    errorMessage,
    success,
    selectOptions = [],
    inputType = 'text',
  } = props;

  const labelClasses = classNames({
    [' ' + classes.labelRootError]: error,
    [' ' + classes.labelRootSuccess]: success && !error,
  });
  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true,
  });
  const marginTop = classNames({
    [classes.marginTop]: labelText === undefined,
  });
  return (
    <FormControl
      {...formControlProps}
      className={formControlProps.className + ' ' + classes.formControl}
    >
      {labelText !== undefined ? (
        <InputLabel
          className={classes.labelRoot + labelClasses}
          htmlFor={id}
          {...labelProps}
        >
          {labelText}
        </InputLabel>
      ) : null}
      {inputType === 'text' ? (
        <Input
          classes={{
            root: marginTop,
            disabled: classes.disabled,
            underline: underlineClasses,
          }}
          id={id}
          {...inputProps}
        />
      ) : null}
      {inputType === 'select' ? (
        <Controller
          as={
            <Select
              classes={{
                root: marginTop,
                disabled: classes.disabled,
              }}
              id={id}
            >
              {selectOptions.map((selectItem) => {
                return (
                  <MenuItem key={selectItem.value} value={selectItem.value}>
                    {selectItem.label}
                  </MenuItem>
                );
              })}
            </Select>
          }
          {...inputProps}
        />
      ) : null}
      {error ? (
        <>
          <Clear className={classes.feedback + ' ' + classes.labelRootError} />
          <p className={classes.labelRootError}> {errorMessage}</p>
        </>
      ) : success ? (
        <Check className={classes.feedback + ' ' + classes.labelRootSuccess} />
      ) : null}
    </FormControl>
  );
}

CustomInput.propTypes = {
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  success: PropTypes.bool,
};
