/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import PeopleIcon from '@material-ui/icons/People';
import AssignmentIcon from '@material-ui/icons/Assignment';
// core components/views for Admin layout
import CustomersPage from 'views/Customers/Customers.js';
import AssignmentsPage from 'views/Assignments/Assignments.js';

const dashboardRoutes = [
  {
    path: '/customers',
    name: 'Customers',
    icon: PeopleIcon,
    component: CustomersPage,
    layout: '/admin',
  },
  {
    path: '/assignments',
    name: 'Assignments',
    icon: AssignmentIcon,
    component: AssignmentsPage,
    layout: '/admin',
  },
];

export default dashboardRoutes;
