import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { DropzoneArea } from 'material-ui-dropzone';

import {
  saveStepData,
  populateStepData,
  uploadAssignmentFile,
} from 'services/dataServices.js';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import CardMedia from '@material-ui/core/CardMedia';

// core components
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';

const useStyles = makeStyles((theme) => ({
  media: {
    width: '50%',
    height: 300,
  },
}));

export default function Step3(props) {
  const classes = useStyles();
  const {
    customerId,
    assignmentId,
    activeStep,
    handleBack,
    handleNext,
  } = props;
  const [isLoading, setIsLoading] = useState(false);

  const [labResultFiles, setLabResultFiles] = useState([]);

  const {
    register,
    handleSubmit,
    // errors,
    reset,
    getValues,
    formState: { isSubmitting },
  } = useForm();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const result = await populateStepData(3, customerId, assignmentId);
      setLabResultFiles(result.items);
      reset({ items: result.items || [] });
      setIsLoading(false);
    };
    if (customerId && assignmentId) {
      fetchData();
    }
  }, [customerId, assignmentId, reset]);

  const handleLabResultFilesDrop = async (file) => {
    const uploadResponse = await uploadAssignmentFile(file);
    file.fileName = uploadResponse.fileName;
    setLabResultFiles((prev) => [
      ...prev,
      { fileName: uploadResponse.fileName, fileUrl: uploadResponse.fileUrl },
    ]);
  };

  const handleSampleImageFilesDelete = async (file) => {
    if (!file.fileName) {
      // if dropzone files where initialized with server data.
      file.fileName = file.name;
    }
    const filteredLabResultFiles = labResultFiles.filter(
      (i) => !file.fileName.includes(i.fileName)
    );
    // maintain form state instead of resetting the whole form
    const formValues = getValues({ nest: true });
    const filteredFormValues = formValues.items.filter(
      (i) => !file.fileName.includes(i.fileName)
    );
    reset({ items: filteredFormValues || [] });
    setLabResultFiles(filteredLabResultFiles);
  };

  const onSubmit = async (data) => {
    data.customerId = customerId;
    data.assignmentId = assignmentId;
    await saveStepData(3, data);
    handleNext();
  };

  if (isLoading) return <CircularProgress />;
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <label>STEP 3</label>
      <DropzoneArea
        acceptedFiles={['application/pdf']}
        filesLimit={18}
        maxFileSize={6000000}
        onDrop={handleLabResultFilesDrop}
        onDelete={handleSampleImageFilesDelete}
        initialFiles={labResultFiles.map((s) => s.fileUrl)}
      />
      <GridContainer justify="center">
        {labResultFiles.map((sampleImage, index) => (
          <GridItem xs={12} sm={6} md={6} key={`fileName${index}`}>
            <input
              type="hidden"
              name={`items[${index}].fileName`}
              value={sampleImage.fileName}
              ref={register}
            />
            <input
              type="hidden"
              name={`items[${index}].fileUrl`}
              value={sampleImage.fileUrl}
              ref={register}
            />
            <CustomInput
              labelText="Description"
              id={`description-${sampleImage.fileName}`}
              inputProps={{
                name: `items[${index}].description`,
                inputRef: register,
              }}
              formControlProps={{
                fullWidth: true,
              }}
            />
            <CardMedia
              className={classes.media}
              image={sampleImage.fileUrl}
              title={sampleImage.description}
            />
          </GridItem>
        ))}
      </GridContainer>
      <Button
        disabled={activeStep === 0}
        onClick={handleBack}
        className={classes.button}
      >
        Back
      </Button>
      <Button type="submit" disabled={isSubmitting} color="primary">
        Save and Next
      </Button>
    </form>
  );
}
